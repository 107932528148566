import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { registerUser, storeUser } from 'store/auth/auth.actions';
import LanguageSwitcher from 'components/shared/LanguageSwitcher';
import { EnvelopeIcon, Spinner } from 'evergreen-ui';
import {
    Header,
    Container,
    FormContainer,
    AsideBlock,
    Form,
    FadeIn,
    Title,
    Headline,
    FieldContainer,
    ButtonContainer,
    Input,
    Footer,
    ErrorMessage,
    ShowPasswordSwitch,
    LanguageSwitcherContainer,
    FormBlock,
    FormTitle,
    Description,
    Subtitle,
    InfoIconWrapper,
    InfoIcon,
    InfoTooltip,
    FieldWrapper,
    ValidationErrorMessage,
    LoadingContainer,
} from './Register.style';
import { Switch, FormControlLabel } from '@material-ui/core';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';
import { ReactComponent as UserIcon } from 'assets/icons/login-user.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/login-password.svg';
import { auth0Client } from 'Auth';
import { useAppSelector } from '../../../store/hooks';
import { fetchPublicConfig } from '../../../store/publicConfig/publicConfig.actions';
import { selectPublicConfig } from '../../../store/publicConfig/publicConfig.selectors';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';
import CompanyLogo from '../../shared/CompanyLogo/CompanyLogo';

const Register = (props) => {
    const { t, isMobileSize, isTabletAndMobileSize } = props;

    const { user, loading, isRegistering } = useAppSelector(
        (state) => state.auth,
    );
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isI18nLoading, setIsI18nLoading] = useState(true);
    const [displayResults, setDisplayResults] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
        useState('');

    const dispatch = useDispatch();
    const { targetGroupId } = useParams();
    const { registeredUser, error } = useSelector((state) => state.auth);
    const { language, logoUrl, companyName } = useSelector(selectPublicConfig);
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const query = new URLSearchParams(useLocation().search);
    const emailParam = query.get('email');
    const firstNameParam = query.get('f_name');
    const lastNameParam = query.get('l_name');
    const uniqueId = query.get('uniqueId');
    const source = query.get('s');

    useEffect(() => {
        if (targetGroupId) {
            dispatch(fetchPublicConfig(targetGroupId));
        }
    }, [targetGroupId, dispatch]);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language).then(() => {
                setIsI18nLoading(false);
            });
        }
    }, [i18n, language]);

    useEffect(() => {
        if (auth0Client) {
            const handleLoggedInUser = async () => {
                const isAuthenticated = await auth0Client.isAuthenticated();
                if (isAuthenticated) {
                    dispatch(storeUser());
                }
            };
            void handleLoggedInUser();
        }
    }, [dispatch, auth0Client]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user) {
            setFirstName(user.given_name);
            setLastName(user.family_name);
            setEmail(user.email.toLowerCase());
            setPassword('Aa123456');
            setConfirmPassword('Aa123456');
        }
    }, [user]);

    useEffect(() => {
        if (registeredUser && registeredUser.invitationId) {
            if (registeredUser.status.includes('UserRegistered')) {
                navigate(
                    `/${registeredUser.companySlug}/${
                        registeredUser.assessmentId
                    }/${registeredUser.invitationId}?message=${
                        registeredUser.emailVerified
                            ? 'registeredAndVerified'
                            : 'verifyEmail'
                    }`,
                );
            }
            if (
                registeredUser.status.includes('UserExists') &&
                registeredUser.status.includes('UserInvited')
            ) {
                setDisplayResults(true);
            }
        }
    }, [registeredUser, navigate]);

    useEffect(() => {
        if (emailParam) {
            setEmail(emailParam.toLowerCase());
        }
        if (firstNameParam) {
            setFirstName(firstNameParam);
        }
        if (lastNameParam) {
            setLastName(lastNameParam);
        }
    }, [emailParam, firstNameParam, lastNameParam]);

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        );
    };

    const handlePasswordValidation = (event) => {
        let newPassword = event.target.value;
        setPassword(newPassword);
        let lowerCase = /[a-z]/g;
        let upperCase = /[A-Z]/g;
        let numbers = /[0-9]/g;
        if (!newPassword.match(lowerCase)) {
            setPasswordErrorMessage(t('REGISTER.PASSWORD_DESC_1'));
        } else if (!newPassword.match(upperCase)) {
            setPasswordErrorMessage(t('REGISTER.PASSWORD_DESC_2'));
        } else if (!newPassword.match(numbers)) {
            setPasswordErrorMessage(t('REGISTER.PASSWORD_DESC_3'));
        } else if (newPassword.length > 20 || newPassword.length < 8) {
            setPasswordErrorMessage(t('REGISTER.PASSWORD_DESC_4'));
        } else {
            setPasswordErrorMessage('');
        }
    };

    const handleConfirmPasswordValidation = (confirmPassword) => {
        setConfirmPassword(confirmPassword);
        if (confirmPassword !== password) {
            setConfirmPasswordErrorMessage(
                t('REGISTER.PASSWORDS_DO_NOT_MATCH'),
            );
        } else setConfirmPasswordErrorMessage('');
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const isEmailValid = validateEmail(email);
        if (password === confirmPassword && isEmailValid) {
            dispatch(
                registerUser(targetGroupId, {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                    integrations: source
                        ? {
                              unique_id: uniqueId ? uniqueId : '',
                              source: source,
                          }
                        : {},
                }),
            );
        }
    };

    if (loading || isI18nLoading) {
        return (
            <LoadingContainer>
                <Spinner size={22} />
                Loading...
            </LoadingContainer>
        );
    }

    const renderErrorMessage = () => {
        let errorMessage;
        if (error && error.errorCode && error.errorParam !== undefined) {
            errorMessage = t(`REGISTER.${error.errorCode}`, {
                param: error.errorParam.split(',').join(', '),
            });
        } else if (error && error.errorCode) {
            errorMessage = t(`REGISTER.${error.errorCode}`);
        }

        return errorMessage;
    };

    return (
        <Container>
            <Header>
                <div />
                <div className="registration-logo">
                    <CompanyLogo logoUrl={logoUrl} />
                </div>
                <LanguageSwitcherContainer>
                    <LanguageSwitcher />
                </LanguageSwitcherContainer>
            </Header>
            <FormContainer>
                <AsideBlock>
                    <Title>
                        <FadeIn>{t('REGISTER.TITLE')}</FadeIn>
                    </Title>
                    <Headline>
                        {companyName && (
                            <FadeIn>
                                {t('REGISTER.HEADLINE', {
                                    company_name: companyName,
                                })}
                            </FadeIn>
                        )}
                        {!companyName && (
                            <FadeIn>{t('REGISTER.DEPRECATED_HEADLINE')}</FadeIn>
                        )}
                    </Headline>
                    <Description>
                        {!!companyName && (
                            <FadeIn>
                                {t('REGISTER.DESCRIPTION', {
                                    company_name: companyName,
                                })}
                            </FadeIn>
                        )}
                        {!companyName && (
                            <FadeIn>
                                {t('REGISTER.DEPRECATED_DESCRIPTION')}
                            </FadeIn>
                        )}
                    </Description>
                    {!isMobileSize && (
                        <>
                            <Subtitle>
                                <FadeIn>{t('REGISTER.SUBTITLE')}</FadeIn>
                            </Subtitle>
                            <Description>
                                <FadeIn>
                                    <Trans i18nKey="REGISTER.SUB_DESCRIPTION">
                                        Link to
                                        <a
                                            href="https://neurolytics.ai/what-to-expect"
                                            target="_blank"
                                            rel="noreferrer">
                                            What to expect
                                        </a>
                                        Link to
                                        <a
                                            href={
                                                'https://neurolytics.ai/what-to-expect#FAQs'
                                            }
                                            target="_blank"
                                            rel="noreferrer">
                                            FAQ
                                        </a>
                                        .
                                    </Trans>
                                </FadeIn>
                            </Description>
                        </>
                    )}
                </AsideBlock>
                {!displayResults && (
                    <FormBlock>
                        <FormTitle>
                            {!!companyName &&
                                t('REGISTER.FORM_TITLE', {
                                    company_name: companyName,
                                })}
                            {!companyName &&
                                t('REGISTER.DEPRECATED_FORM_TITLE')}{' '}
                        </FormTitle>
                        <Form>
                            <FieldContainer
                                className={`${
                                    user || firstNameParam ? 'disabled' : ''
                                } ${firstName ? 'is-completed' : ''}`}>
                                <label htmlFor="neurolytics-first-name">
                                    <UserIcon
                                        className={`${
                                            user || firstNameParam
                                                ? 'disabled'
                                                : ''
                                        } ${firstName ? 'is-completed' : ''}`}
                                    />
                                </label>
                                <Input
                                    id="neurolytics-first-name"
                                    autocomplete="given-name"
                                    name="neurolytics-first-name"
                                    placeholder={t(
                                        'REGISTER.FIRST_NAME_PLACEHOLDER',
                                    )}
                                    value={firstName}
                                    disabled={!!user || !!firstNameParam}
                                    onChange={(event) =>
                                        setFirstName(event.target.value)
                                    }
                                    required
                                />
                            </FieldContainer>
                            <FieldContainer
                                className={`${
                                    user || lastNameParam ? 'disabled' : ''
                                } ${lastName ? 'is-completed' : ''}`}>
                                <label htmlFor="neurolytics-last-name">
                                    <UserIcon
                                        className={`${
                                            user || lastNameParam
                                                ? 'disabled'
                                                : ''
                                        } ${lastName ? 'is-completed' : ''}`}
                                    />
                                </label>
                                <Input
                                    id="neurolytics-last-name"
                                    autocomplete="family-name"
                                    name="neurolytics-last-name"
                                    placeholder={t(
                                        'REGISTER.LAST_NAME_PLACEHOLDER',
                                    )}
                                    value={lastName}
                                    disabled={!!user || !!lastNameParam}
                                    onChange={(event) =>
                                        setLastName(event.target.value)
                                    }
                                />
                            </FieldContainer>
                            <FieldContainer
                                className={`${
                                    user || emailParam ? 'disabled' : ''
                                } ${
                                    email && !validateEmail(email)
                                        ? 'has-error'
                                        : ''
                                } ${
                                    email && validateEmail(email)
                                        ? 'is-completed'
                                        : ''
                                }`}>
                                <label htmlFor="neurolytics-email">
                                    <EnvelopeIcon
                                        size={20}
                                        color="muted"
                                        className={`${
                                            user || emailParam ? 'disabled' : ''
                                        } ${
                                            email && !validateEmail(email)
                                                ? 'has-error'
                                                : ''
                                        } ${
                                            email && validateEmail(email)
                                                ? 'is-completed'
                                                : ''
                                        }`}
                                    />
                                </label>
                                <Input
                                    id="neurolytics-email"
                                    name="email"
                                    type="email"
                                    placeholder={t(
                                        'REGISTER.EMAIL_PLACEHOLDER',
                                    )}
                                    value={email}
                                    disabled={!!user || !!emailParam}
                                    onChange={(event) =>
                                        setEmail(
                                            event.target.value.toLowerCase(),
                                        )
                                    }
                                    required
                                />
                            </FieldContainer>
                            {email && !validateEmail(email) && (
                                <ValidationErrorMessage>
                                    {t('REGISTER.INVALID_EMAIL')}
                                </ValidationErrorMessage>
                            )}
                            <FieldWrapper>
                                <FieldContainer
                                    className={`${user ? 'disabled' : ''} ${
                                        passwordErrorMessage ? 'has-error' : ''
                                    } ${
                                        password && !passwordErrorMessage
                                            ? 'is-completed'
                                            : ''
                                    }`}>
                                    <label htmlFor="neurolytics-password">
                                        <PasswordIcon
                                            className={`${
                                                user ? 'disabled' : ''
                                            } ${
                                                passwordErrorMessage
                                                    ? 'has-error'
                                                    : ''
                                            } ${
                                                password &&
                                                !passwordErrorMessage
                                                    ? 'is-completed'
                                                    : ''
                                            }`}
                                        />
                                    </label>
                                    <Input
                                        id="neurolytics-password"
                                        name="neurolytics-password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        placeholder={t(
                                            'REGISTER.PASSWORD_PLACEHOLDER',
                                        )}
                                        value={password}
                                        disabled={!!user}
                                        onChange={(event) => {
                                            handlePasswordValidation(event);
                                            if (confirmPassword) {
                                                handleConfirmPasswordValidation(
                                                    confirmPassword,
                                                );
                                            }
                                        }}
                                        required
                                    />
                                </FieldContainer>
                                {!isTabletAndMobileSize && (
                                    <InfoIconWrapper>
                                        <InfoIcon>?</InfoIcon>
                                        <InfoTooltip>
                                            <p>
                                                {t(
                                                    'REGISTER.PASSWORD_DESC_ALL',
                                                )}
                                            </p>
                                        </InfoTooltip>
                                    </InfoIconWrapper>
                                )}
                            </FieldWrapper>
                            <ValidationErrorMessage>
                                {passwordErrorMessage}
                            </ValidationErrorMessage>
                            <FieldContainer
                                className={`${user ? 'disabled' : ''} ${
                                    confirmPasswordErrorMessage
                                        ? 'has-error'
                                        : ''
                                } ${
                                    confirmPassword &&
                                    !confirmPasswordErrorMessage
                                        ? 'is-completed'
                                        : ''
                                }`}>
                                <label htmlFor="neurolytics-confirm-password">
                                    <PasswordIcon
                                        className={`${user ? 'disabled' : ''} ${
                                            confirmPasswordErrorMessage
                                                ? 'has-error'
                                                : ''
                                        } ${
                                            confirmPassword &&
                                            !confirmPasswordErrorMessage
                                                ? 'is-completed'
                                                : ''
                                        }`}
                                    />
                                </label>
                                <Input
                                    id="neurolytics-confirm-password"
                                    name="neurolytics-confirm-password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder={t(
                                        'REGISTER.CONFIRM_PASSWORD_PLACEHOLDER',
                                    )}
                                    value={confirmPassword}
                                    disabled={!!user}
                                    onChange={(event) => {
                                        handleConfirmPasswordValidation(
                                            event.target.value,
                                        );
                                    }}
                                    required
                                />
                            </FieldContainer>
                            <ValidationErrorMessage>
                                {confirmPasswordErrorMessage}
                            </ValidationErrorMessage>
                            <ShowPasswordSwitch>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showPassword}
                                            disabled={!!user}
                                            onChange={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    }
                                    label={t('REGISTER.SHOW_PASSWORD')}
                                />
                            </ShowPasswordSwitch>

                            {!!error && (
                                <ErrorMessage visible={error}>
                                    {renderErrorMessage()}
                                </ErrorMessage>
                            )}
                            <ButtonContainer>
                                <ShadowButton
                                    onClick={onSubmit}
                                    size={'small'}
                                    disabled={
                                        !!passwordErrorMessage ||
                                        isRegistering ||
                                        !password ||
                                        !confirmPassword ||
                                        password !== confirmPassword ||
                                        !email ||
                                        !firstName ||
                                        !lastName ||
                                        !validateEmail(email)
                                    }>
                                    <FadeIn>
                                        {t('REGISTER.CONTINUE_BUTTON')}
                                    </FadeIn>
                                </ShadowButton>
                            </ButtonContainer>
                        </Form>
                    </FormBlock>
                )}
                {displayResults && (
                    <FormBlock className="questions">
                        <Headline>
                            <FadeIn>
                                {t('REGISTER.USER_EXISTS', { email })}
                            </FadeIn>
                        </Headline>
                        <ShadowButton
                            size={'small'}
                            onClick={() => {
                                if (
                                    registeredUser &&
                                    registeredUser.invitationId &&
                                    registeredUser.status.includes('UserExists')
                                ) {
                                    navigate(
                                        `/${registeredUser.companySlug}/${registeredUser.assessmentId}/${registeredUser.invitationId}`,
                                    );
                                }
                            }}>
                            <FadeIn>
                                {t('REGISTER.RESULTS_CONTINUE_BUTTON')}
                            </FadeIn>
                        </ShadowButton>
                    </FormBlock>
                )}
                {isMobileSize && (
                    <div className={'questions'}>
                        <Subtitle>
                            <FadeIn>{t('REGISTER.SUBTITLE')}</FadeIn>
                        </Subtitle>
                        <Description>
                            <FadeIn>
                                <Trans i18nKey="REGISTER.SUB_DESCRIPTION">
                                    Link to
                                    <a
                                        href="https://neurolytics.ai/what-to-expect"
                                        target="_blank"
                                        rel="noreferrer">
                                        What to expect
                                    </a>
                                    Link to
                                    <a
                                        href={
                                            'https://neurolytics.ai/what-to-expect#FAQs'
                                        }
                                        target="_blank"
                                        rel="noreferrer">
                                        FAQ
                                    </a>
                                    .
                                </Trans>
                            </FadeIn>
                        </Description>
                    </div>
                )}
            </FormContainer>
            <div className="spacer"></div>
            <Footer>
                <img src={poweredByNeurolyticsLogo} alt="Neurolytics Logo" />
            </Footer>
        </Container>
    );
};

export default withMediaQuery(withTranslation()(Register));
