import React, { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import {
    Container,
    TwoColumnsLayout,
    ContentColumn,
    Question,
    Text,
    InfoBlock,
    GridContainer,
    TextFieldContainer,
    ContentFooter,
    ButtonContainer,
    BoldItalic,
    Header,
    RateWrapper,
} from './FeedbackQuestionnaire.style';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import poweredByNeurolyticsLogo from '../../assets/images/powered-by-neurolytics.png';
import withMediaQuery from '../../hoc/MediaQuery/withMediaQuery';
import CompanyLogo from '../../components/shared/CompanyLogo/CompanyLogo';
import { ReactComponent as FeedbackEmoji1 } from 'assets/images/Feedback_emoji_1.svg';
import { ReactComponent as FeedbackEmojiFilled1 } from 'assets/images/Feedback_emoji_1_filled.svg';
import { ReactComponent as FeedbackEmoji2 } from 'assets/images/Feedback_emoji_2.svg';
import { ReactComponent as FeedbackEmojiFilled2 } from 'assets/images/Feedback_emoji_2_filled.svg';
import { ReactComponent as FeedbackEmoji3 } from 'assets/images/Feedback_emoji_3.svg';
import { ReactComponent as FeedbackEmojiFilled3 } from 'assets/images/Feedback_emoji_3_filled.svg';
import { ReactComponent as FeedbackEmoji4 } from 'assets/images/Feedback_emoji_4.svg';
import { ReactComponent as FeedbackEmojiFilled4 } from 'assets/images/Feedback_emoji_4_filled.svg';

const useStyles = makeStyles(
    {
        root: {
            borderRadius: 0,
            border: '1px solid #435970 !important',
            backgroundColor: '#fff',
        },
        notchedOutline: {
            borderWidth: '0 !important',
        },
        input: {
            '&::placeholder': {
                fontStyle: 'italic',
            },
        },
    },
    { name: 'MuiOutlinedInput' },
);

const FeedbackQuestionnaire = (props) => {
    const { onComplete, isMobileSize, t, companyName } = props;
    const classes = useStyles();
    const { companySlug } = useParams();
    const publicScansConfig = useSelector((state) => state.config.publicScansConfig);
    const company = publicScansConfig[companySlug];
    const [feedbackResponses, setFeedbackResponses] = useState({
        experienceQuestion: '',
        improveAppQuestion: '',
        feedbackRate: null,
    });
    const [selectedRating, setSelectedRating] = useState(null);
    const logoUrl =
        company && company.logoUrl
            ? company.logoUrl
            : 'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png';

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFeedbackResponses((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleRatingChange = (rating) => {
        setSelectedRating(rating);
        setFeedbackResponses((prevState) => ({
            ...prevState,
            feedbackRate: rating,
        }));
    };

    const feedbackResponseObjectWithId = {
        id: 'feedback-questionnaire',
        questions: Object.entries(feedbackResponses).map(([key, value]) => {
            return { id: key, value: value };
        }),
    };

    return (
        <Container>
            <Header>
                <CompanyLogo logoUrl={logoUrl} />
            </Header>
            <TwoColumnsLayout>
                <ContentColumn>
                    <Text>
                        <h1>
                            {t('FEEDBACK_QUESTIONNAIRE.INTRODUCTION.TITLE')}
                        </h1>
                        <p>
                            <Trans
                                i18nKey="FEEDBACK_QUESTIONNAIRE.INTRODUCTION.MESSAGE"
                                components={[<BoldItalic />]}
                            />
                        </p>
                    </Text>
                </ContentColumn>
                <ContentColumn>
                    <InfoBlock>
                        <GridContainer>
                            <Question>
                                {t(
                                    'FEEDBACK_QUESTIONNAIRE.GRID.EXPERIENCE_QUESTION',
                                )}

                                <TextFieldContainer>
                                    <TextField
                                        multiline
                                        fullWidth={true}
                                        minRows="6"
                                        maxRows="10"
                                        margin="none"
                                        placeholder={t(
                                            'FEEDBACK_QUESTIONNAIRE.GRID.QUESTION_PLACEHOLDER',
                                        )}
                                        value={
                                            feedbackResponses.experienceQuestion
                                        }
                                        name="experienceQuestion"
                                        onChange={handleChange}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                root: classes.root,
                                                notchedOutline:
                                                    classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </TextFieldContainer>
                            </Question>
                            <Question>
                                {t(
                                    'FEEDBACK_QUESTIONNAIRE.GRID.SUGGESTION_IMPROVE_APP_QUESTION',
                                    {
                                        company_name: companyName,
                                    },
                                )}
                                <TextFieldContainer>
                                    <TextField
                                        multiline
                                        fullWidth={true}
                                        minRows="6"
                                        maxRows="10"
                                        className={classes.textField}
                                        placeholder={t(
                                            'FEEDBACK_QUESTIONNAIRE.GRID.QUESTION_PLACEHOLDER',
                                        )}
                                        value={
                                            feedbackResponses.improveAppQuestion
                                        }
                                        name="improveAppQuestion"
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </TextFieldContainer>
                            </Question>
                            <Question className={'feedback-rate'}>
                                {t('FEEDBACK_QUESTIONNAIRE.GRID.FEEDBACK_RATE')}
                                <RateWrapper>
                                    <div
                                        className="feedback-rate"
                                        onClick={() => handleRatingChange(1)}>
                                        {selectedRating === 1 ? (
                                            <FeedbackEmojiFilled1 />
                                        ) : (
                                            <FeedbackEmoji1 />
                                        )}
                                    </div>
                                    <div
                                        className="feedback-rate"
                                        onClick={() => handleRatingChange(2)}>
                                        {selectedRating === 2 ? (
                                            <FeedbackEmojiFilled2 />
                                        ) : (
                                            <FeedbackEmoji2 />
                                        )}
                                    </div>
                                    <div
                                        className="feedback-rate"
                                        onClick={() => handleRatingChange(3)}>
                                        {selectedRating === 3 ? (
                                            <FeedbackEmojiFilled3 />
                                        ) : (
                                            <FeedbackEmoji3 />
                                        )}
                                    </div>
                                    <div
                                        className="feedback-rate"
                                        onClick={() => handleRatingChange(4)}>
                                        {selectedRating === 4 ? (
                                            <FeedbackEmojiFilled4 />
                                        ) : (
                                            <FeedbackEmoji4 />
                                        )}
                                    </div>
                                </RateWrapper>
                            </Question>
                        </GridContainer>
                    </InfoBlock>
                    {!isMobileSize && (
                        <ContentFooter>
                            <ButtonContainer>
                                <ShadowButton
                                    size={isMobileSize ? 'small' : 'default'}
                                    onClick={() =>
                                        onComplete(
                                            feedbackResponseObjectWithId,
                                            'feedback',
                                        )
                                    }>
                                    {t(
                                        'FEEDBACK_QUESTIONNAIRE.INTRODUCTION.CONTINUE_BUTTON',
                                    )}
                                </ShadowButton>
                            </ButtonContainer>
                        </ContentFooter>
                    )}
                </ContentColumn>
            </TwoColumnsLayout>
            {isMobileSize && (
                <>
                    <div className="spacer"></div>
                    <ContentFooter>
                        <ButtonContainer>
                            <ShadowButton
                                size={isMobileSize ? 'small' : 'default'}
                                onClick={() =>
                                    onComplete(
                                        feedbackResponseObjectWithId,
                                        'feedback',
                                    )
                                }>
                                {t(
                                    'FEEDBACK_QUESTIONNAIRE.INTRODUCTION.CONTINUE_BUTTON',
                                )}
                            </ShadowButton>
                        </ButtonContainer>
                    </ContentFooter>
                </>
            )}
            <img
                className="img-footer"
                src={poweredByNeurolyticsLogo}
                alt="Neurolytics Logo"
            />
        </Container>
    );
};

export default withMediaQuery(withTranslation()(FeedbackQuestionnaire));
