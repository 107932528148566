import configActionTypes from './config.types';

export const storeScanIds = (companySlug, assessmentId, invitationId) => ({
    type: configActionTypes.STORE_SCAN_IDS,
    payload: { companySlug, assessmentId, invitationId },
});

export const fetchPublicScansConfig = (
    companySlug,
    assessmentId,
    invitationId,
    navigate,
) => ({
    type: configActionTypes.FETCH_PUBLIC_SCANS_CONFIG,
    payload: { companySlug, assessmentId, invitationId, navigate },
});

export const fetchConfig = (assessmentId, targetGroupId, invitationId) => ({
    type: configActionTypes.FETCH_CONFIG,
    payload: { assessmentId, targetGroupId, invitationId },
});

export const finishScan = (assessmentId, invitationId) => ({
    type: configActionTypes.FINISH_SCAN,
    payload: { assessmentId, invitationId },
});
