import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cameraActions } from '../../../store/camera/camera.actions';
import { preloaderActions } from '../../../store/preloader/preloader.actions';
import { uploadsActions } from '../../../store/uploads/uploads.actions';
import TermsAndConditions from '../../../views/TermsAndConditions';
import CultureFitModule from '../../CultureFit/CultureFitModule';
import AssessmentEndingView from '../../../views/AssessmentEndingView';
import CustomAssessmentEndingView from '../../../views/AssessmentEndingView/DataCollectionAssessmentEndingView/DataCollectionAssessmentEndingView';
import DataCollectionTermsAndConditions from '../../../views/TermsAndConditions/DataCollectionTermsConditions/DataCollectionTermsConditions';
import ErrorView from '../../../views/ErrorView';
import UploadingProgressionView from '../../../views/UploadingProgressionView';
import AssessmentProgressionView from '../../../views/AssessmentProgressionView/AssessmentProgressionView';
import FeedbackQuestionnaire from '../../../views/FeedbackQuestionnaire/FeedbackQuestionnaire';
import QuestionnaireController from '../../Questionnaire/QuestionnaireController/QuestionnaireController';
import CustomModuleController from '../../CustomModule/CustomModuleController/CustomModuleController';
import CameraSetupView from 'components/shared/CameraSetupView/CameraSetupView';
import SessionAnalytics from 'services/SessionAnalytics';
import InterviewController from 'components/Interview/InterviewController/InterviewController';
import { getVideosToPreload } from 'utils/preloader';
import Logger from 'services/Logger';
import CameraService from '../../../services/CameraService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finishScan } from '../../../store/config/config.actions';
import { isMobileOrTablet } from '../../../utils/detect-mobile';
import MobileNotSupportedView from '../../shared/MobileNotSupportedView/MobileNotSupportedView';
import { isScanOnlyWorkPreferences } from '../../../utils/isScanOnlyWorkPreferences';

const getAssessmentProgressionSteps = (modules) => {
    const steps = [];
    const modulesIncludedInProgressionView = [
        { id: 'culture-fit', label: 'Cultural Fit' },
        { id: 'culture-fit-questionnaire', label: 'Work Preferences' },
        { id: 'speaking', label: 'Speaking' },
        { id: 'mental-test', label: 'Cognitive Abilities' },
        {
            id: 'questionnaire',
            label: { nl: 'Vragenlijsten', en: 'Questionnaire' },
        },
    ];

    modules.forEach((module) => {
        if (module.id === 'culture-fit-questionnaire') {
            const step = modulesIncludedInProgressionView.find(
                (m) => m.id === 'culture-fit-questionnaire',
            );
            steps.push(step);
        }
        if (module.id === 'culture-fit') {
            const step = modulesIncludedInProgressionView.find(
                (m) => m.id === 'culture-fit',
            );
            steps.push(step);
        }
        if (module.type === 'interview-module') {
            const step = modulesIncludedInProgressionView.find(
                (m) => m.id === 'speaking',
            );
            steps.push(step);
        }
        if (module.id.includes('mental-test')) {
            const step = modulesIncludedInProgressionView.find(
                (m) => m.id === 'mental-test',
            );
            steps.push(step);
        }
        if (module.id === 'data-collection-scan') {
            if (module.customModuleLabel) {
                const step = {
                    id: 'questionnaire',
                    label: module.customModuleLabel,
                };
                steps.push(step);
            } else {
                const step = modulesIncludedInProgressionView.find(
                    (m) => m.id === 'questionnaire',
                );
                steps.push(step);
            }
        }
    });
    return steps;
};

const AssessmentController = (props) => {
    const { companySlug, invitationId } = props;
    const { publicScansConfig, scans } = useSelector((state) => state.config);
    const { modules, dataCollection, isCompleted, groupRelations } =
        scans[invitationId];
    const { user } = useSelector((state) => state.auth);
    const camera = useSelector((state) => state.camera);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [terminateScan, setTerminateScan] = useState(false);
    const [shouldAlertOnExit, setShouldAlertOnExit] = useState(true);
    const { companyName, defaultLanguage } = publicScansConfig[companySlug];
    let { language } = scans[invitationId];

    if (!language) {
        language = defaultLanguage;
    }

    const [progressionView, setProgressionView] = useState({
        id: 'progression-view',
        type: 'progression-view',
        steps: getAssessmentProgressionSteps(modules),
        active: -1,
    });
    const [currentModule, setCurrentModule] = useState(modules[0]);
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const isQuestionnaireOnlyScan = isScanOnlyWorkPreferences(modules);
    const isDeviceMobileOrTablet = isMobileOrTablet();

    const dispatchVideosToPreload = () => {
        getVideosToPreload(modules).then((videos) => {
            Logger.logInfo(
                'AssessmentController - Preloading the following videos',
                {
                    videos,
                },
            );
            if (videos.length > 0) {
                videos.forEach((video, index) => {
                    setTimeout(() => {
                        dispatch(
                            preloaderActions.preloadVideo(
                                video.videoId,
                                video.videoUrl,
                            ),
                        );
                    }, index * 100);
                });
            }
        });
    };

    const updateModule = () => {
        const shouldDisplayProgressionView = (lastModule) => {
            const isScanCultureFitOnlyScanWithoutVideo =
                modules
                    .map((module) => module.id)
                    .includes('culture-fit-questionnaire') &&
                !modules
                    .map((module) => module.type)
                    .includes('culture-fit-video') &&
                !modules.map((module) => module.id).includes('mental-test') &&
                !modules
                    .map((module) => module.type)
                    .includes('interview-module');

            return (
                (isScanCultureFitOnlyScanWithoutVideo &&
                    lastModule.id === 'terms-and-conditions') ||
                lastModule.id === 'allow-camera-access' ||
                lastModule.id === 'culture-fit' ||
                lastModule.id === 'culture-fit-questionnaire' ||
                lastModule.id === 'interview' ||
                lastModule.id === 'mental-test' ||
                lastModule.id === 'data-collection-scan'
            );
        };

        const nextModule = modules[currentModuleIndex + 1];
        if (nextModule) {
            if (shouldDisplayProgressionView(currentModule)) {
                SessionAnalytics.onModuleNavigation(
                    progressionView,
                    currentModule,
                );
                setCurrentModule(progressionView);
                setProgressionView({
                    ...progressionView,
                    active: progressionView.active + 1,
                });
            } else {
                Logger.logInfo(
                    `AssessmentController - Updating module - current module: ${currentModule.type}, currentModuleIndex: ${currentModuleIndex}`,
                    {
                        nextModule: {
                            id: nextModule.id,
                            type: nextModule.type,
                        },
                    },
                );
                SessionAnalytics.onModuleNavigation(nextModule, currentModule);

                setCurrentModuleIndex(currentModuleIndex + 1); // this line should be called before setCurrentModule so the current module know about the next module
                setCurrentModule(nextModule);
            }
        }
    };

    const submitQuestionnaire = (form, questionnaireId) => {
        const logFeedbackQuestions = (questionnaire) => {
            questionnaire.questions.forEach((question) => {
                Logger.logInfo(
                    `Feedback - Question=${question.id} - Answer=${question.value} - user_id=${user.id}`,
                );
            });
        };

        const params = {
            invitationId: invitationId,
            file: form,
            fileType: 'submission',
            fileId: questionnaireId + '-questionnaire',
        };
        if (questionnaireId.includes('feedback')) {
            logFeedbackQuestions(form);
        }
        Logger.logInfo(
            'AssessmentController - Submitting questionnaire',
            params,
        );
        dispatch(uploadsActions.upload(params));
    };
    const onBackButtonEvent = (e) => {
        if (!isDeviceMobileOrTablet || isQuestionnaireOnlyScan) {
            e.preventDefault();
            if (!terminateScan) {
                if (window.confirm(t('EXIT_ALERT'))) {
                    setTerminateScan(true);
                    window.history.back();
                } else {
                    window.history.pushState(null, null, window.location.href);
                    setTerminateScan(false);
                }
            }
        }
    };

    useEffect(() => {
        if (isCompleted) {
            navigate('/');
            return;
        }
        if (
            groupRelations &&
            groupRelations.type === 'linked' &&
            !groupRelations.is_before_scan_completed
        ) {
            navigate(
                `/${companySlug}/${groupRelations.before_scan_id}/${groupRelations.before_invitation_id}?nextScanId=${scans[invitationId].assessmentId}&nextInvitationId=${invitationId}`,
            );
        } else {
            SessionAnalytics.onModuleNavigation(modules[0]);
            dispatchVideosToPreload();

            Logger.logInfo('AssessmentController - Initializing controller', {
                modules,
                state: {
                    progressionView,
                    currentModule,
                    currentModuleIndex,
                },
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Disable back button
    useEffect(() => {
        if (
            shouldAlertOnExit &&
            (!isDeviceMobileOrTablet || isQuestionnaireOnlyScan)
        ) {
            window.history.pushState(null, null, window.location.href);
            window.addEventListener('popstate', onBackButtonEvent);
            return () => {
                window.removeEventListener('popstate', onBackButtonEvent);
            };
        }
    }, [shouldAlertOnExit]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
            return '';
        };
        if (
            shouldAlertOnExit &&
            (!isDeviceMobileOrTablet || isQuestionnaireOnlyScan)
        ) {
            window.addEventListener('beforeunload', unloadCallback);
            return () =>
                window.removeEventListener('beforeunload', unloadCallback);
        }
    }, [shouldAlertOnExit]);

    const componentRender = () => {
        if (modules) {
            switch (currentModule.type) {
                case 'terms-and-conditions': {
                    if (dataCollection) {
                        return (
                            <DataCollectionTermsAndConditions
                                user={user}
                                language={language}
                                onComplete={() => updateModule()}
                            />
                        );
                    } else {
                        return (
                            <TermsAndConditions
                                user={user}
                                language={language}
                                companyName={companyName}
                                onComplete={() => updateModule()}
                                introVideos={
                                    currentModule.videos
                                        ? currentModule.videos
                                        : null
                                }
                            />
                        );
                    }
                }
                case 'allow-camera-access': {
                    const videoStream = CameraService.getVideoStream();
                    return (
                        <CameraSetupView
                            cameraError={camera.error}
                            videoStream={camera.haveAccess && videoStream}
                            onAccept={() =>
                                dispatch(cameraActions.captureUserMedia())
                            }
                            onComplete={() => {
                                dispatch(
                                    cameraActions.stopCapturingUserMedia(),
                                );
                                updateModule();
                            }}
                        />
                    );
                }
                case 'culture-fit-video': {
                    return (
                        <CultureFitModule
                            videoUrl={currentModule.videoUrl}
                            onComplete={() => {
                                dispatch(
                                    cameraActions.stopRecordingUserMedia(
                                        invitationId,
                                        true,
                                    ),
                                );
                                updateModule();
                            }}
                            onStartRecording={() => {
                                dispatch(
                                    cameraActions.recordUserMedia(
                                        invitationId,
                                        'culture-fit',
                                        true,
                                    ),
                                );
                            }}
                        />
                    );
                }
                case 'assessment-questionnaire': {
                    return (
                        <QuestionnaireController
                            language={language}
                            module={currentModule}
                            isQuestionnaireOnlyScan={isQuestionnaireOnlyScan}
                            onQuestionnaireComplete={(
                                form,
                                questionnaireId,
                            ) => {
                                submitQuestionnaire(form, questionnaireId);
                                if (currentModule.id === 'mental-test') {
                                    dispatch(
                                        cameraActions.stopRecordingUserMedia(
                                            invitationId,
                                            true,
                                        ),
                                    );
                                }
                                updateModule();
                            }}
                            onRecordUserMedia={(fileId) => {
                                dispatch(
                                    cameraActions.recordUserMedia(
                                        invitationId,
                                        fileId,
                                        true,
                                    ),
                                );
                            }}
                            onStopRecordingUserMedia={() => {
                                dispatch(
                                    cameraActions.stopRecordingUserMedia(
                                        invitationId,
                                        true,
                                    ),
                                );
                            }}
                            OnResetRecordingChunk={(newFileId) => {
                                dispatch(
                                    cameraActions.resetRecordingChunk(
                                        newFileId,
                                        invitationId,
                                    ),
                                );
                            }}
                        />
                    );
                }
                case 'custom-module': {
                    return (
                        <CustomModuleController
                            invitationId={invitationId}
                            language={language}
                            isQuestionnaireOnlyScan={isQuestionnaireOnlyScan}
                            modules={currentModule.modules}
                            onComplete={updateModule}
                        />
                    );
                }
                case 'interview-module': {
                    return (
                        <InterviewController
                            invitationId={invitationId}
                            module={currentModule}
                            onComplete={updateModule}
                        />
                    );
                }
                case 'progression-view': {
                    return (
                        <AssessmentProgressionView
                            steps={progressionView.steps}
                            activeStep={progressionView.active}
                            onComplete={updateModule}
                        />
                    );
                }
                case 'feedback-questionnaire': {
                    return (
                        <FeedbackQuestionnaire
                            companyName={companyName}
                            onComplete={(form, questionnaireId) => {
                                submitQuestionnaire(form, questionnaireId);
                                updateModule();
                            }}
                        />
                    );
                }
                case 'assessment-upload-progression': {
                    return (
                        <UploadingProgressionView
                            invitationId={invitationId}
                            onComplete={updateModule}
                        />
                    );
                }
                case 'assessment-ending': {
                    if (dataCollection) {
                        return (
                            <CustomAssessmentEndingView
                                modules={modules}
                                onScanFinished={() => {
                                    dispatch(
                                        finishScan(
                                            scans[invitationId].assessmentId,
                                            invitationId,
                                        ),
                                    );
                                    setShouldAlertOnExit(false);
                                }}
                            />
                        );
                    } else {
                        return (
                            <AssessmentEndingView
                                modules={modules}
                                companySlug={companySlug}
                                groupRelations={groupRelations}
                                onScanFinished={() => {
                                    dispatch(
                                        finishScan(
                                            scans[invitationId].assessmentId,
                                            invitationId,
                                        ),
                                    );
                                    setShouldAlertOnExit(false);
                                }}
                            />
                        );
                    }
                }
                default: {
                    Logger.logError(
                        `Error on AssessmentController - currentModule.type=${currentModule.type}`,
                    );
                    return <ErrorView error={{ code: 'UseDefault' }} />;
                }
            }
        }
    };
    return (
        <>
            {(!isDeviceMobileOrTablet || isQuestionnaireOnlyScan) &&
                componentRender()}
            {isDeviceMobileOrTablet && !isQuestionnaireOnlyScan && (
                <MobileNotSupportedView />
            )}
        </>
    );
};

export default AssessmentController;
