import { publicConfigActionTypes } from './publicConfig.types';

export const publicConfigInitialState = {
    groupName: '',
    language: 'nl-NL',
    companyName: '',
    logoUrl: 'https://s3.eu-west-1.amazonaws.com/neurolytics.public/neurolytics/images/neurolytics-logo.png',
    loading: false,
    error: null,
};

const publicConfigReducer = (state = publicConfigInitialState, action) => {
    switch (action.type) {
        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG_SUCCESS: {
            const { groupName, language, companyName, logoUrl } =
                action.payload;
            return {
                ...state,
                groupName: groupName,
                language: language,
                companyName: companyName,
                logoUrl: logoUrl,
                loading: false,
                error: null,
            };
        }

        case publicConfigActionTypes.FETCH_PUBLIC_CONFIG_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error: error,
            };
        }

        default:
            return state;
    }
};

export default publicConfigReducer;
