import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Login from 'components/Auth/Login/Login';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import { selectAuthState } from 'store/auth/auth.selectors';
import { auth0Client } from 'Auth';
import useAuth from 'hooks/useAuth';
import {
    fetchConfig,
    fetchPublicScansConfig,
    storeScanIds,
} from '../../../store/config/config.actions';
import SessionAnalytics from '../../../services/SessionAnalytics';
import i18next from 'i18next';
import AssessmentController from '../../Assessment/AssessmentController/AssessmentController';
// import { LoadingEllipsis } from "../../components/Auth/Authenticator/Authenticator.style";
import ErrorView from '../../../views/ErrorView/ErrorView';
import Logger from '../../../services/Logger';

const Authenticator = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const { companySlug, assessmentId, invitationId } = useParams();
    const { user, loading: authLoading } = useSelector(selectAuthState);
    const {
        publicScansConfig,
        scans,
        loading: configLoading,
    } = useSelector((state) => state.config);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const auth0code = query.get('code');
    const auth0state = query.get('state');
    const { onRedirectCallback } = useAuth();
    const [loggerInitiated, setLoggerInitiated] = useState(false);

    useEffect(() => {
        // If we are redirected back from Auth0 universal login:
        // - Redirect to specific scan OR scans list

        if (auth0Client && auth0code && auth0state) {
            onRedirectCallback().then((appState) => {
                if (appState) {
                    const { companySlug, assessmentId, invitationId } =
                        appState;
                    if (companySlug && assessmentId && invitationId) {
                        navigate(
                            `/${companySlug}/${assessmentId}/${invitationId}`,
                        );
                    }
                } else {
                    navigate('/');
                }
            });
        }
    }, [auth0code, auth0state, auth0Client]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (companySlug && assessmentId && invitationId) {
            dispatch(
                fetchPublicScansConfig(
                    companySlug,
                    assessmentId,
                    invitationId,
                    navigate,
                ),
            );
            dispatch(storeScanIds(companySlug, assessmentId, invitationId));
            if (user) {
                const scan = user.scans.find(
                    (scan) => scan.invitation_id === invitationId,
                );
                if (!scan) {
                    setError('WrongInvitationId');
                } else {
                    const targetGroupId = scan.target_group_id;
                    Logger.authenticateLogger(user, targetGroupId);
                    setLoggerInitiated(true);
                    dispatch(
                        fetchConfig(assessmentId, targetGroupId, invitationId),
                    );
                }
            }
        }
    }, [user, companySlug, assessmentId, invitationId, dispatch, navigate]);

    useEffect(() => {
        if (scans && scans[invitationId] && publicScansConfig && publicScansConfig[companySlug]) {
            SessionAnalytics.onScanStart(invitationId);
            const language =
                scans && scans[invitationId] && scans[invitationId].language
                    ? scans[invitationId].language
                    : publicScansConfig[companySlug].defaultLanguage;
            i18next.changeLanguage(language);
        }
    }, [publicScansConfig, companySlug, invitationId, scans]);

    return (
        <>
            {(authLoading || !auth0Client) && <ProgressSpinner />}
            {/*{!error && isAuthenticated === null && <LoadingEllipsis />}*/}

            {!authLoading && !!auth0Client && !!user && !!children && children}

            {!authLoading &&
                !!auth0Client &&
                !error &&
                !user &&
                (companySlug && invitationId ? (
                    <Login
                        companySlug={companySlug}
                        invitationId={invitationId}
                    />
                ) : (
                    <Login />
                ))}

            {!authLoading &&
                !configLoading &&
                !!auth0Client &&
                !error &&
                !children &&
                user &&
                scans[invitationId] &&
                scans[invitationId].modules &&
                loggerInitiated && (
                    <AssessmentController
                        companySlug={companySlug}
                        invitationId={invitationId}
                    />
                )}

            {error && <ErrorView error={{ code: error }} />}
        </>
    );
};

export default Authenticator;
