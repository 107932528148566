import configActionTypes from './config.types';

export const configInitialState = {
    publicScansConfig: {},
    scans: {},
    loading: true,
    error: null,
};

const configReducer = (state = configInitialState, action) => {
    switch (action.type) {
        case configActionTypes.STORE_SCAN_IDS: {
            const { companySlug, assessmentId, invitationId } = action.payload;
            return {
                ...state,
                scans: {
                    ...state.scans,
                    [invitationId]: {
                        ...state.scans[invitationId],
                        companySlug,
                        assessmentId,
                        invitationId,
                    },
                },
            };
        }

        case configActionTypes.FETCH_PUBLIC_SCANS_CONFIG: {
            return {
                ...state,
                loading: true,
            };
        }

        case configActionTypes.FETCH_PUBLIC_SCANS_CONFIG_SUCCESS: {
            const {
                companySlug,
                companyName,
                defaultLanguage,
                logoUrl,
                groupName,
            } = action.payload;
            return {
                ...state,
                publicScansConfig: {
                    ...state.publicScansConfig,
                    [companySlug]: {
                        ...state.publicScansConfig[companySlug],
                        companyName,
                        defaultLanguage,
                        logoUrl,
                        groupName,
                    },
                },
                loading: false,
            };
        }

        case configActionTypes.FETCH_PUBLIC_SCANS_CONFIG_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error,
            };
        }

        case configActionTypes.FETCH_CONFIG: {
            return {
                ...state,
                loading: true,
            };
        }

        case configActionTypes.FETCH_CONFIG_SUCCESS: {
            const {
                assessmentId,
                targetGroupId,
                targetGroupName,
                invitationId,
                companySlug,
                modules,
                language,
                assessmentName,
                description,
                autoAnalysis,
                dataCollection,
                isCompleted,
                groupRelations,
            } = action.payload;
            return {
                ...state,
                scans: {
                    ...state.scans,
                    [invitationId]: {
                        ...state.scans[invitationId],
                        targetGroupId,
                        targetGroupName,
                        assessmentId,
                        companySlug,
                        modules,
                        language,
                        assessmentName,
                        description,
                        autoAnalysis,
                        dataCollection,
                        isCompleted,
                        groupRelations,
                    },
                },
                loading: false,
            };
        }

        case configActionTypes.FETCH_CONFIG_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error,
            };
        }

        case configActionTypes.FINISH_SCAN_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                error,
            };
        }

        default:
            return state;
    }
};

export default configReducer;
