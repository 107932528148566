const configActions = {
    STORE_SCAN_IDS: 'CONFIG/STORE_SCAN_IDS',
    FETCH_PUBLIC_SCANS_CONFIG: 'CONFIG/FETCH_PUBLIC_SCANS_CONFIG',
    FETCH_PUBLIC_SCANS_CONFIG_SUCCESS: 'CONFIG/FETCH_PUBLIC_SCANS_CONFIG_SUCCESS',
    FETCH_PUBLIC_SCANS_CONFIG_FAILURE: 'CONFIG/FETCH_PUBLIC_SCANS_CONFIG_FAILURE',
    FETCH_CONFIG: 'CONFIG/FETCH_CONFIG',
    FETCH_CONFIG_SUCCESS: 'CONFIG/FETCH_CONFIG_SUCCESS',
    FETCH_CONFIG_FAILURE: 'CONFIG/FETCH_CONFIG_FAILURE',
    FINISH_SCAN: 'CONFIG/FINISH_SCAN',
    FINISH_SCAN_SUCCESS: 'CONFIG/FINISH_SCAN_SUCCESS',
    FINISH_SCAN_FAILURE: 'CONFIG/FINISH_SCAN_FAILURE',
};

export default configActions;
