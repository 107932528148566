import { put, call } from 'redux-saga/effects';
import { publicConfigActionTypes } from './publicConfig.types';
import NeurolyticsAPI from 'services/NeurolyticsAPI';

export function* fetchPublicConfig(action) {
    try {
        const { targetGroupId } = action.payload;
        const { groupName, language, companyName, logoUrl } = yield call(
            [NeurolyticsAPI, 'fetchPublicConfigApi'],
            targetGroupId,
        );

        yield put({
            type: publicConfigActionTypes.FETCH_PUBLIC_CONFIG_SUCCESS,
            payload: {
                groupName,
                language,
                companyName,
                logoUrl,
            },
        });
    } catch (error) {
        yield put({
            type: publicConfigActionTypes.FETCH_PUBLIC_CONFIG_SUCCESS,
            payload: {
                error,
            },
        });
    }
}
