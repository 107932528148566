import { takeEvery, takeLatest, all } from 'redux-saga/effects';

import configActionTypes from './config/config.types';
import * as configEffects from './config/config.effects';
import { publicConfigActionTypes } from './publicConfig/publicConfig.types';
import * as publicConfigEffects from './publicConfig/publicConfig.effects';
import { authActionTypes } from './auth/auth.actions';
import * as authEffects from './auth/auth.effects';
import { cameraActionTypes } from './camera/camera.actions';
import * as cameraEffects from './camera/camera.effects';
import { preloaderActionTypes } from './preloader/preloader.actions';
import * as preloaderEffects from './preloader/preloader.effects';
import { uploadsActionTypes } from './uploads/uploads.actions';
import * as uploadsEffects from './uploads/uploads.effects';

function* rootSaga() {
    // config effects
    yield all([
        takeEvery(configActionTypes.FETCH_PUBLIC_SCANS_CONFIG, configEffects.fetchPublicScansConfig),
    ]);
    yield all([
        takeEvery(configActionTypes.FETCH_CONFIG, configEffects.fetchConfig),
    ]);
    yield all([
        takeEvery(configActionTypes.FINISH_SCAN, configEffects.finishScan),
    ]);


    // publicConfig effects
    yield all([
        takeEvery(
            publicConfigActionTypes.FETCH_PUBLIC_CONFIG,
            publicConfigEffects.fetchPublicConfig,
        ),
    ]);

    // auth effects
    yield all([takeEvery(authActionTypes.INIT_AUTH0, authEffects.initAuth0)]);
    yield all([takeEvery(authActionTypes.INIT_LOGIN, authEffects.initLogin)]);
    yield all([takeEvery(authActionTypes.STORE_USER, authEffects.storeUser)]);
    yield all([takeEvery(authActionTypes.REFRESH_TOKEN, authEffects.refreshTokenEffect)]);
    yield all([
        takeEvery(authActionTypes.REGISTER_USER, authEffects.registerUser),
    ]);

    // camera effects
    yield all([
        takeEvery(
            cameraActionTypes.CAPTURE_USER_MEDIA,
            cameraEffects.captureUserMedia,
        ),
    ]);
    yield all([
        takeEvery(
            cameraActionTypes.STOP_CAPTURING_USER_MEDIA,
            cameraEffects.stopCapturingUserMedia,
        ),
    ]);
    yield all([
        takeEvery(
            cameraActionTypes.RECORD_USER_MEDIA,
            cameraEffects.recordUserMedia,
        ),
    ]);
    yield all([
        takeEvery(
            cameraActionTypes.STOP_RECORDING_USER_MEDIA,
            cameraEffects.stopRecordingUserMedia,
        ),
    ]);
    yield all([
        takeEvery(
            cameraActionTypes.DISPATCH_BLOB_UPLOAD,
            cameraEffects.dispatchBlobUpload,
        ),
    ]);
    yield all([
        takeEvery(
            cameraActionTypes.RESET_RECORDER,
            cameraEffects.resetRecorder,
        ),
    ]);
    yield all([
        takeLatest(
            cameraActionTypes.RESET_RECORDING_CHUNK,
            cameraEffects.resetRecordingChunk,
        ),
    ]);

    // preloader effects
    yield all([
        takeEvery(
            preloaderActionTypes.PRELOAD_VIDEO,
            preloaderEffects.preloadVideo,
        ),
    ]);
    yield all([
        takeEvery(
            preloaderActionTypes.PRELOAD_VIDEO_FETCHING,
            preloaderEffects.fetchingVideo,
        ),
    ]);

    // upload effects
    yield all([takeEvery(uploadsActionTypes.UPLOAD, uploadsEffects.upload)]);
    yield all([
        takeEvery(
            uploadsActionTypes.UPLOAD_NEXT_IN_QUEUE,
            uploadsEffects.uploadNextInQueue,
        ),
    ]);
    // yield all([takeEvery(uploadsActionTypes.UPLOAD_SUCCESS, uploadsEffects.uploadNextInQueue)]);
}

export default rootSaga;
